// src/directives/lazy-bg.js
export default {
  mounted(el, binding) {
    const loadBackgroundImage = () => {
      const imgUrl = binding.value;
      el.style.backgroundImage = `url(${imgUrl})`;
    };

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            loadBackgroundImage();
            observer.unobserve(el); // 停止观察
          }
        });
      },
      {
        rootMargin: '0px 0px 50px 0px', // 提前 50px 加载
      }
    );

    observer.observe(el); // 开始观察
  },
};
