export default {
  mounted(el) {
    const handleScroll = () => {
      const rect = el.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      // 控制元素的显示/隐藏
      if (rect.top <= windowHeight * 0.9 && rect.bottom >= 0) {
        setTimeout(() => {
          el.classList.add("visible");
        }, 100); // 延迟触发动画
      } else {
        el.classList.remove("visible");
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    el._handleScroll = handleScroll;
  },
  unmounted(el) {
    window.removeEventListener("scroll", el._handleScroll);
  },
};
