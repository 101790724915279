<template>
    <div class="homePage">
        <div class="tips" @click="onContact">
            <img src="@/assets/img/tips.png" class="telegram" loading="lazy">
            <div class="business">联系商务</div>
        </div>
        <img v-if="isPhone" src="@/assets/video/playVideo.png" class="playVideo" @click="onPlayVideo" loading="lazy" />
        <!-- 数据模块 -->
        <div class="dataModular">
            <img src="../assets/img/icon.png" class="logo" loading="lazy">
            <!-- <img src="../assets/img/yuyan.png" class="language"> -->
            <div class="image-container" ref="pauseArea">
                <div v-if="isMouseenter">
                    <img v-if="!currentShow" src="../assets/img/pwa_title.png" class="title" loading="lazy">
                    <img v-else src="../assets/img/cloak_title.png" class="title" loading="lazy">
                </div>
                <div v-else>
                    <img v-if="!show" v-fade-in-once src="../assets/img/pwa_title.png" class="title image-reveal"
                        loading="lazy">
                    <img v-else v-fade-in-once src="../assets/img/cloak_title.png" class="title image-reveal"
                        loading="lazy">
                </div>
            </div>
            <img src="../assets/img/experience.png" class="experience" @click="onExperience" loading="lazy">
            <div v-fade-in-once class="card_box fade-in">
                <div class="card" v-lazy-bg="require('@/assets/img/banner_di.png')">
                    <div class="card_title">100W+</div>
                    <div class="card_text">每日安装用户</div>
                </div>
                <div class="card" v-lazy-bg="require('@/assets/img/banner_di.png')">
                    <div class="card_title">{{ formattedInstallCount }}</div>
                    <div class="card_text">今日安装用户</div>
                </div>
                <div class="card" v-lazy-bg="require('@/assets/img/banner_di.png')">
                    <div class="card_title">3000+</div>
                    <div class="card_text">服务客户</div>
                </div>
                <div class="card" v-lazy-bg="require('@/assets/img/banner_di.png')">
                    <div class="card_title">20+</div>
                    <div class="card_text">涉及行业</div>
                </div>
                <div class="card" v-lazy-bg="require('@/assets/img/banner_di.png')">
                    <div class="card_title">65%</div>
                    <div class="card_text">安装率</div>
                </div>
                <div class="card" v-lazy-bg="require('@/assets/img/banner_di.png')">
                    <div class="card_title">2634</div>
                    <div class="card_text">活跃用户</div>
                </div>
            </div>
        </div>
        <div style="padding: 0 13.9375rem;">
            <!-- pwa技术 -->
            <pwaArea />
            <!-- 技术优势 -->
            <advanAreaNew v-if="!isPhone" />
            <advantageArea v-else />
            <!-- 核心功能 -->
            <coreArea />
            <!-- 斗篷超链的优势 -->
            <capeAdvantage />
            <!-- 斗篷核心技术 -->
            <capeCore />
            <!-- 服务承诺 -->
            <serviceArea />
            <!-- 案例 -->
            <caseArea />
        </div>
        <!-- 页脚 -->
        <div class="floor">
            <img src="../assets/img/icon.png" class="floor_logo" loading="lazy">
            <img src="../assets/img/contact_us.png" class="contact_us" @click="onContact" loading="lazy">
            <!-- <div class="title">Copyright @ 2024 Realm Software Inc. All rights reserved.</div> -->
        </div>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, reactive, ref, computed, watch } from 'vue';
import pwaArea from './components/pwaArea.vue';
import advantageArea from './components/advantageArea.vue';
import advanAreaNew from './components/advanArea_new.vue';
import coreArea from './components/coreArea.vue';
import capeAdvantage from './components/capeAdvantage.vue';
import serviceArea from './components/serviceArea.vue';
import capeCore from './components/capeCore.vue';
import caseArea from './components/caseArea.vue';

// const maxWidth = 1980; // 最大宽度限制
// const clientWidth = Math.min(document.documentElement.clientWidth, maxWidth); // 限制宽度
const isPhone = ref(false)
function setRemUnit() {
    const baseSize = 16; // 基准字体大小
    const designWidth = 1920; // 设计稿宽度
    const clientWidth = document.documentElement.clientWidth;
    const scale = clientWidth / designWidth; // 当前视口宽度与设计稿的比例
    const fontSize = baseSize * scale; // 根据视口计算的字体大小
    // 如果字体小于 10px，进行调整
    if (fontSize < 10) {
        // 设置 html 的 font-size 为 10px，避免强制放大
        document.documentElement.style.fontSize = '10px';
        document.documentElement.style.height = '100vh';
        // 使用 transform: scale() 进行缩放，适应小屏幕设备
        const scaleFactor = clientWidth / 1200; // 基于屏幕宽度来计算缩放比例
        document.body.style.transform = `scale(${scaleFactor})`; // 缩放页面内容
        document.body.style.transformOrigin = 'top left';
        document.body.style.width = '1200px';

    } else {
        // 设置 html 的 font-size 来应用 rem 布局
        document.documentElement.style.fontSize = fontSize + 'px';
        // 清除 transform，恢复默认大小
        document.body.style.transform = 'none';
        document.body.style.width = '100%'; // 恢复默认宽度
    }

    // 判断是否为手机设备
    isPhone.value = clientWidth <= 900;
}
// 初始化
setRemUnit();
// 监听窗口变化
window.addEventListener('resize', setRemUnit);

// 初始化
setRemUnit();
// 监听窗口变化
window.addEventListener('resize', setRemUnit);
// 联系我们
const onContact = () => {
    window.location.href = "https://t.me/KKPWA_bot"
}
// 播放视频
const onPlayVideo = () => {
    window.location.href = "https://admin.kkpwa.com/demoVideo"
}
// 立即体验
const onExperience = () => {
    //  window.location.href = "https://admin.ys-test.top"
    window.location.href = "https://admin.kkpwa.com/"
}
const minInstallCount = ref(0); // 最小安装人数
const maxInstallCount = ref(0); // 最大安装人数
const startOfDay = ref(new Date()); // 当天的开始时间
const currentInstallCount = ref(0); // 当前人数
// 格式化显示安装人数
const formattedInstallCount = computed(() =>
    currentInstallCount.value.toLocaleString()
);
// 每次刷新页面时生成数据范围或从 localStorage 恢复
const initializeDailyData = () => {
    const now = new Date();
    now.setHours(0, 0, 0, 0); // 当天 0 点
    startOfDay.value = now;
    // 检查是否已有存储数据
    const storedData = JSON.parse(localStorage.getItem("installData"));
    // 如果是当天的数据，则恢复存储范围
    if (storedData && storedData.date === now.toISOString()) {
        minInstallCount.value = storedData.minInstallCount;
        maxInstallCount.value = storedData.maxInstallCount;
    } else {
        // 否则生成新数据范围
        generateDailyTarget();
        localStorage.setItem(
            "installData",
            JSON.stringify({
                date: now.toISOString(),
                minInstallCount: minInstallCount.value,
                maxInstallCount: maxInstallCount.value,
            })
        );
    }
};
// 随机生成当天的目标范围
const generateDailyTarget = () => {
    const min = 10000; // 最小值范围
    const max = 100000; // 最大值范围
    minInstallCount.value = Math.floor(Math.random() * (max - min + 1) + min);
    maxInstallCount.value = Math.floor(Math.random() * (max - min + 1) + min);
    if (minInstallCount.value > maxInstallCount.value) {
        [minInstallCount.value, maxInstallCount.value] = [
            maxInstallCount.value,
            minInstallCount.value,
        ];
    }
};
// 根据刷新时间动态计算安装人数
const calculateInstallCount = () => {
    const now = new Date();
    const elapsedTime = (now - startOfDay.value) / (24 * 60 * 60 * 1000); // 已过比例
    currentInstallCount.value = Math.floor(
        minInstallCount.value +
        elapsedTime * (maxInstallCount.value - minInstallCount.value)
    );
};
const pauseArea = ref(null)
const show = ref(false)
const currentShow = ref(false)
const isMouseenter = ref(false)
const pauseAnimation = () => {
    currentShow.value = JSON.parse(JSON.stringify(show.value))
    isMouseenter.value = true
}
const resumeAnimation = () => {
    isMouseenter.value = false
}
onMounted(async () => {
    initializeDailyData(); // 初始化数据范围
    calculateInstallCount(); // 计算当前人数
    setInterval(() => {
        show.value = !show.value
    }, 4000);
    // 添加事件监听器
    if (pauseArea.value) {
        pauseArea.value.addEventListener('mouseenter', pauseAnimation);
        pauseArea.value.addEventListener('mouseleave', resumeAnimation);
    }
})
onBeforeUnmount(() => {
    if (pauseArea.value) {
        pauseArea.value.removeEventListener('mouseenter', pauseAnimation);
        pauseArea.value.removeEventListener('mouseleave', resumeAnimation);
    }
});
</script>

<style scoped lang="scss">
@font-face {
    font-family: 'HYWenHei';
    src: url('../assets/fonts/HYWenHei.ttf') format('truetype');
    font-display: swap;
    /* 使用系统字体临时替代 */
}

@font-face {
    font-family: 'AlibabaPuHuiTiB';
    src: url('../assets/fonts/Alibaba-PuHuiTi-Bold.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'AlibabaPuHuiTiR';
    src: url('../assets/fonts/Alibaba-PuHuiTi-Regular.otf') format('opentype');
    font-display: swap;
}

@keyframes changeBackground {
    0% {
        background-image: url('../assets/img/banner02.jpg');
        background-size: cover;
    }

    50% {
        background-image: url('../assets/img/banner01.jpg');
        background-size: cover;
    }

    100% {
        background-image: url('../assets/img/banner02.jpg');
        background-size: cover;
    }
}

.homePage {

    width: 100%;
    background: #18233D;

    .tips {
        position: relative;
        width: 8.8125rem;
        position: fixed;
        right: 3.75rem;
        top: 37rem;
        cursor: pointer;
        z-index: 99;

        .telegram {
            width: 8.8125rem;
        }

        .business {
            position: absolute;
            font-size: .9375rem;
            color: #d0f1ff;
            bottom: 1.3rem;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .playVideo {
        width: 9rem;
        position: relative;
        z-index: 99;
        position: fixed;
        right: 3.75rem;
        top: 20rem;
        cursor: pointer;
    }

    .dataModular {
        position: relative;
        width: 100%;
        height: 49.1875rem;
        background-size: cover;
        animation: changeBackground 8s infinite;

        .logo {
            position: absolute;
            top: 1.9375rem;
            left: 15rem;
            height: 5.375rem;
        }

        .language {
            position: absolute;
            top: 3.125rem;
            right: 7.3125rem;
            width: 10.4375rem;
            height: 5.5625rem;
        }

        /* 图片容器 */
        .image-container {
            position: absolute;
            top: 13.75rem;
            left: calc(50% - 27.125rem);

            .title {
                width: 54.25rem;
            }

        }

        .experience {
            position: absolute;
            top: 26.3125rem;
            left: calc(50% - 11.9063rem);
            width: 22rem;
            cursor: pointer;
        }

        .card_box {
            position: absolute;
            top: 36.0625rem;
            width: 100%;
            height: 12rem;
            text-align: center;

            .card {
                display: inline-block;
                margin: 0 .1875rem;
                padding-top: 2.5625rem;
                width: 13.875rem;
                height: 9.6875rem;
                border-radius: 1.875rem;
                border: .0625rem solid #41BAFF;
                background-size: cover;
                background-repeat: no-repeat;

                .card_title {
                    font-family: 'AlibabaPuHuiTiR';
                    font-size: 2.5rem;
                    color: #73ECFE;
                    height: 2rem;
                    line-height: 2rem;
                }

                .card_text {
                    font-family: 'AlibabaPuHuiTiR';
                    font-size: 1.1875rem;
                    color: #FFFFFF;
                    height: 1.5625rem;
                    line-height: 1.5625rem;
                    margin-top: 1.625rem;

                }
            }
        }
    }

    // 页脚
    .floor {
        position: relative;
        width: 100%;
        height: 15.25rem;
        background: #14171F;
        display: flex;
        justify-content: center;
        z-index: 9;

        .title {
            position: absolute;
            bottom: 1.5625rem;
            font-family: 'HYWenHei';
            font-size: 1.25rem;
            color: #717171;
        }

        .floor_logo {
            position: absolute;
            top: 2.0625rem;
            left: 15rem;
            height: 5.375rem;
        }

        .contact_us {
            position: absolute;
            top: 2.375rem;
            right: 15rem;
            width: 16.375rem;
            cursor: pointer;
        }
    }
}

/* 初始状态：从左到右遮罩 */
.image-reveal {
    clip-path: inset(0 100% 0 0);
    /* 隐藏图片，右侧完全遮挡 */
    transition: clip-path 1s ease;
}

.image-reveal.visible {
    clip-path: inset(0 0 0 0);
    /* 完全显示图片 */
}
</style>
